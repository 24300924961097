var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "appForm",
        {
          attrs: {
            "to-list": _vm.toList,
            "screen-roster-list": _vm.screenRosterList,
            width: "400",
          },
          on: {
            getSearchCallback: _vm.getSearchCallback,
            screenRosterParams: _vm.screenRosterParams,
            exportFileList: _vm.exportFileList,
          },
        },
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _c("a-space", [
                _c("div", [
                  _c("span", [_vm._v(" 本月报税人员：")]),
                  _c("span", { staticClass: "number" }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "onJobId",
          "offset-bottom": 60,
          "table-ref": ".div-content",
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "staffName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvater", {
                  attrs: {
                    "table-avater": scope.record.avatar,
                    "table-name": scope.record.staffName,
                    "on-job-id": scope.record.onJobId,
                    "staff-id": scope.record.staffId,
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }